import './Landing.css'
import React , { useEffect, useState}from 'react';
import { useSelector} from 'react-redux'
import { motion } from 'framer-motion';
import 'animate.css';
import Slider from "react-slick";
import { useTranslation } from "react-i18next";





import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import HeaderMenu from '../../components/HeaderMenu/HeaderMenu'
import IP_checker from '../../components/IP_checker/IP_checker'
import AffProgramCalculator from '../../components/AffProgramCalculator/AffProgramCalculator';


import firstBlockImg from '../../images/first__block/first__page--img.webp' 
import whatIsVPN640 from '../../images/whatIsVPN/whatIsVPN640.webp'
import logo_desktop from '../../images/logo_desktop.webp'

import Safety from '../../images/benefits/Safety.webp'
import reliability from '../../images/benefits/reliability.webp'
import speed from '../../images/benefits/speed.webp'
import tgBot from '../../images/benefits/tgBot.webp'

import earthIcon from '../../images/tarifs/earthIcon.svg'
import infinityIcon from '../../images/tarifs/infinityIcon.svg'
import shildIcon from '../../images/tarifs/shildIcon.svg'
import speedIcon from '../../images/tarifs/speedIcon.svg'

import card from '../../images/affiliateProgram/card.svg'
import dollar from '../../images/affiliateProgram/dollar.svg'
import users from '../../images/affiliateProgram/users.svg'

import  tgIcon from '../../images/anyQwestions/tgIcon.png'
import mailIcon from '../../images/anyQwestions/mailIcon.png'
import fuckYouIcon from '../../images/anyQwestions/fuckYouIcon.png'


function Landing(){
    
    const [scroll, setScroll] = React.useState(0);
    const [isMobile, setIsMobile] = useState(700)
    const [menuAnim, setMenuAnim] = useState(0)
    const [menuAnim2, setMenuAnim2] = useState(0)
    const [animBack, setAnimBack] = useState('menuNone')
    const [tarifPrice , setTarifPrice] = useState('0₽')
    const [tarifActive , setTarifActive] = useState(0)
    const [localemodalVisible, setLocalemodalVisible] = useState(false)

    
    const { t , i18n} = useTranslation();

    const sliders = document.querySelectorAll('.slick-slide > div')
    sliders.forEach(el => {
        el.classList.add('slide__style');
    })
    
    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
    };


    const menuVisible = useSelector((state) => state.landing.HederMenuVisible)

    function clickChangeLocale(lang){
        setLocalemodalVisible(false)
        changeLanguage(lang)
        
    }

    const tarifs = [
        {time : t("trialPeriod"), price: t("priceTrial"), styles: tarifActive == 0 ? 'tarifActive' : 'tarif'},
        {time : t("month1"), price: t("price1Month"), styles: tarifActive == 1 ? 'tarifActive' : 'tarif'},
        {time : t("month3"), price: t("price3Months"), styles: tarifActive == 2 ? 'tarifActive' : 'tarif'},
        {time : t("month6"), price: t("price6Months"), styles: tarifActive == 3 ? 'tarifActive' : 'tarif'},
        {time : t("month12"), price: t("price12Months"), styles: tarifActive == 4? 'tarifActive' : 'tarif'},
        
    ]
    

    const handleScroll = () => {
        setScroll(window.scrollY);
        setLocalemodalVisible(false)
    };

    const handleResize = () => {
        if(window.innerWidth > 1800){
        }
        else if (window.innerWidth < 1800 && window.innerWidth > 1600) {
            setIsMobile(530)
        }else if (window.innerWidth < 1400 && window.innerWidth > 1200){
            setIsMobile(280)
        }
        else if (window.innerWidth < 1200 && window.innerWidth > 1000){
            setIsMobile(300)
        }
        else if (window.innerWidth < 1000 && window.innerWidth > 800){
            setIsMobile(250)
        }else if (window.innerWidth < 800 && window.innerWidth > 600){
            setIsMobile(220)
        }
        else if (window.innerWidth < 600 && window.innerWidth > 400){
            setIsMobile(220)
        }
        else if (window.innerWidth < 400 && window.innerWidth > 200){
            setIsMobile(240)
        }


      }

    React.useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);


    useEffect(() => {
        window.addEventListener("resize", handleResize)
        if(scroll >= 250 ){
            setMenuAnim(-50)
            setMenuAnim2(window.innerWidth <= 800 ? 60 : 70)

        }else if( scroll < 220){
            setMenuAnim(0)
            setMenuAnim2(-150)
        }
       
    })
    useEffect(() => {
        if(menuVisible == false){
            setAnimBack('menuSticky')
       }
    },[menuVisible])

    

    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
          <div
            className={className}
            style={{ ...style, display: "none" }}
            onClick={onClick}
          />
        );
      }
      
      function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
          <div
            className={className}
            style={{ ...style, display: "none"}}
            onClick={onClick}
          />
        );
      }

    const settings = {
        focusOnSelect: true,
        infinite: true,
        slidesToShow: window.innerWidth > 600 ?  3 : 2,
        slidesToScroll: window.innerWidth > 600 ?  3 : 2,
        speed: 500,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
      };

     


    return(
        <div className='site'>
            <IP_checker/>
            <span id=''></span>
            <Header localemodalVisible={localemodalVisible} setLocalemodalVisible={setLocalemodalVisible} logo={logo_desktop} header_class={scroll < isMobile ? 'header' : 'header__sticky animate__animated animate__fadeInDown'}/>
            {localemodalVisible &&
                <div className={scroll < isMobile ? 'localemodal' : 'localemodal__down'}>
                    <span className='localemodal__link' onClick={() => clickChangeLocale('en')}>English</span>
                    <span className='localemodal__link' onClick={() => clickChangeLocale('ru')}>Русский</span>
                </div>
            }
            {window.innerWidth <= 1000 && 
                <motion.div className={animBack}
                    initial={{  y: menuAnim }}
                    animate={{  y: menuVisible ? -400 : scroll < isMobile ? menuAnim : menuAnim2}}
                    transition={{ duration: 0.8 }}
                >
                    <HeaderMenu/>
                </motion.div>
            }
            <div  className='first__block'>
                <div className='first__block--info'>
                    <h1 className='info__h1'>YourGuard VPN</h1>
                    <p className='info__text'>{t("infoText")}</p>
                    <div className='info__buttons'>
                        <a name='tarifs' href='#tarifs'><button className=' first__block--btn'>{t("tariffsStrong")}</button></a>
                        <a name='tg' href='https://t.me/YourGuardVPN_bot'><button className=' first__block--btn'>{t("tryForFree")}</button></a>                    </div>
                </div>
                <img alt='phone' src={firstBlockImg} className='first__block--img'/>
            </div>
            <div id='whatIsVPN' className='whatIsVPN'>
                <div className='whatIsVPN__info'>
                    <h2 className='h2'>{t("whatIsVPNStrong")}</h2>
                    <p className='whatIsVPN__info--text'>
                        {t("whatIsVPNInfo")}
                    </p>
                </div>
                <div>
                    <img alt='vpn scheme' className='whatIsVPN__img' src={whatIsVPN640}/>
                </div>
            </div>
            <div id='whyYG' className='whyYG'>
                <h2 className='h2'>{t("whyYourGuardVPN")}</h2>
                <div className='whyYG__cards'>
                    <div className='whyYG__cards--card'>
                        <div className='card__img--wrap'>
                            <img alt='advantage img' className='whyYG__card--img' src={reliability}/>
                        </div>
                        
                        <div className='whyYG__cards--info'>
                            <h3 className='info__h3'>{t("reliability")}</h3>
                            <p className='whyYG__info--text'>
                                {t("reliabilityText")}
                            </p>
                        </div>
                    </div>
                    <div className='whyYG__cards--card'>
                        <div className='card__img--wrap'>
                            <img alt='advantage img' className='whyYG__card--img' src={Safety}/>
                        </div>
                        
                        <div className='whyYG__cards--info'>
                            <h3 className='info__h3'>{t("safety")}</h3>
                            <p className='whyYG__info--text'>
                                {t("safetyText")}
                            </p>
                        </div>
                    </div>
                    <div className='whyYG__cards--card'>
                        <div className='card__img--wrap'>
                            <img alt='advantage img' className='whyYG__card--img' src={speed}/>
                        </div>
                        
                        <div className='whyYG__cards--info'>
                            <h3 className='info__h3'>{t("speed")}</h3>
                            <p className='whyYG__info--text'>
                                {t("speedText")}
                            </p>
                        </div>
                    </div>
                    <div className='whyYG__cards--card'>
                        <div className='card__img--wrap'>
                            <img alt='advantage img' className='whyYG__card--img' src={tgBot}/>
                        </div>
                        
                        <div className='whyYG__cards--info'>
                            <h3 className='info__h3'>{t("telegramBot")}</h3>
                            <p className='whyYG__info--text'>
                                {t("telegramBotText")}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div id='howGetVPN' className='howGetVPN'>
                <h2 className='h2'>{t("howToGetVPNStrong")}</h2>
                <ul className='howGetVPN__ul'>
                    <li className='howGetVPN__ul--li'>{t("telegramBotStep")}<a name='tg' href='https://t.me/YourGuard_VPN_bot'><span className='ul__li--link'>@YourGuard_VPN_bot</span></a></li>
                    <li className='howGetVPN__ul--li'>{t("getTokenStep")} <span>{t("tokenInfo")}</span></li>
                    <li className='howGetVPN__ul--li'>{t("installOutlineStep")}</li>
                    <li className='howGetVPN__ul--li'>{t("copyTokenStep")} <span>{t("outlineClient")}</span></li>
                    <li className='howGetVPN__ul--li'>{t("toggleVpnStep")} <span>{t("toggleButtonsInfo")}</span></li>
                    <li className='howGetVPN__ul--li'>{t("enjoySurfingStep")}</li>
                </ul>
                <p className='howGetVPN__bold--link'>{t("detailedInstructions")}<a name='tg' href='https://t.me/YourGuard_VPN_bot'><span className='ul__li--link'>telegra.ph/Test-article-10-22</span></a></p>
            </div> */}
            <div id='tarifs' className='tarifs'>
                <h2 className='h2 h2__tarifs'>{t("tariffsStrong")}</h2>
                <div className='tarifs__wrap'>
                    {window.innerWidth <= 1000 &&
                        <div>
                            <Slider {...settings}>
                                {tarifs.map((item, index) => {
                                    return(
                                        <div className={item.styles} onClick={() => {setTarifPrice(item.price); setTarifActive(index)}} {...item} key={index}>{item.time}</div>
                                    )
                                })}
                            </Slider>
                        </div>
                    }
                    {window.innerWidth > 1000 &&
                        <div className='tarifs__wrap--tarif'>
                            {tarifs.map((item, index) => {
                                return(
                                    <div className={item.styles} onClick={() => {setTarifPrice(item.price); setTarifActive(index)}} {...item} key={index}>{item.time}</div>
                                )
                            })}
                        </div>
                    }
                    
                    <div className='tarifs__wrap--info'>
                        <div className='price__wrap'>
                            {tarifActive == 0 &&
                                <p className='info__price--zero'>{t("trialPeriodText")}</p>
                            }
                            {tarifActive > 0 &&
                                <p className='info__price'><span className='info__price--cost'>{tarifPrice}</span>{t("pricePerMonth")}</p>
                            }
                            
                           
                        </div>
                        <div className='points__wrap'>
                            <div className='info__point'>
                                <div>
                                    <img alt='info icon' src={shildIcon} className='info__point--img tarif__img--top'/>
                                </div>
                                <p className='info__point--text'>{t("safeChannelText")}</p>
                            </div>
                            <div className='info__point'>
                                <div className='tarif__imgDiv--margin'>
                                    <img alt='info icon' src={infinityIcon} className='info__point--img tarif__img--top'/>
                                </div>
                                <p className='info__point--text'>{t("unlimitedDevicesText")}</p>
                            </div>
                            <div className='info__point'>
                                <div>
                                    <img alt='info icon' src={speedIcon} className='info__point--img tarif__img--top'/>
                                </div>
                                <p className='info__point--text'>{t("bandwidthText")}</p>
                            </div>
                            <div className='info__point'>
                                <div>
                                    <img alt='info icon' src={earthIcon} className='info__point--img'/>
                                </div>
                                <p className='info__point--text'>{t("locationsText")}</p>
                            </div>
                            <div className='info__point'>
                                <div>
                                    <img alt='info icon' src={infinityIcon} className='info__point--img'/>
                                </div>
                                <p className='info__point--text'>{t("unlimitedTrafficText")}</p>
                            </div>
                            <a name='tg' href='https://t.me/YourGuardVPN_bot' ><button className='info__btn'>{t("buyButtonText")}</button></a>                        </div>
                           
                    </div>
                </div>
            </div>
            {/* <div id='affiliateProgram' className='affiliateProgram'>
                <h2 className='h2'>{t("partnerProgram")}</h2>
                <div className='afProgram__wrap'>
                    <div className='afProgram__wrap--wrapper'>
                        <div className='afProgram__wrap--point'>
                            <div>
                                <img alt='info icon' className='afProgram__img' src={dollar}/>
                            </div>
                            <p>{t("partnerProgramText")}</p>
                        </div>
                        <div className='afProgram__wrap--point'>
                            <div>
                                <img alt='info icon' className='card__img' src={card}/>
                            </div>
                            <p>{t("partnerProgramCard")}</p>
                        </div>
                        <div className='afProgram__wrap--point'>
                            <div>
                                <img alt='info icon' className='afProgram__img' src={users}/>
                            </div>
                            <p>{t("partnerProgramReferral")}<br/><span>{t("freeSubscriptionDays")}</span></p>
                        </div>
                        <div className='afProgram__wrap--point'>
                            <div>
                                <img alt='info icon' className='afProgram__img' src={users}/>
                            </div>
                            <p>{t("partnerProgramSubscription")}<br/><span>{t("freeSubscriptionWeeks")}</span></p>
                        </div>
                    </div>
                    <AffProgramCalculator/> 
                </div>
                <p className='bold__link'>{t("moreDetails")}<a name='telegrath' href='https://t.me/YourGuardVPN_bot'><span className='bold__link--span'>https://t.me/YourGuardVPN_bot</span></a></p>
            </div> */}
            <div id='anyQwestions' className='anyQwestions'>
                <h2 className='h2'>{t("contactQuestions")}</h2>
                <div className='anyQwestions__wrap'>
                    <a name='tg' href='https://t.me/YourGuardVPN_bot'>
                        <div className='anyQwestions__wrap--point'>
                            <div>
                                <img alt='tgicon' src={tgIcon}/>
                            </div>
                            <p>{t("vpnBot")}</p>
                        </div>
                    </a>
                    <a name='tg' href='https://t.me/YourGuardVPN'>
                        <div className='anyQwestions__wrap--point'>
                            <div>
                                <img alt='tgicon' src={tgIcon}/>
                            </div>
                            <p>{t("newsChannel")}</p>
                        </div>
                    </a>
                    <a name='help' href='https://t.me/YourGuardVPN_support'>
                        <div className='anyQwestions__wrap--point'>
                            <div>
                                <img alt='tgicon' src={tgIcon}/>
                            </div>
                            <p>{t("techSupport")}</p>
                        </div>
                    </a>
                    <a name='tg' href='https://t.me/YourGuardVPN_forum'>
                        <div className='anyQwestions__wrap--point'>
                            <div>
                                <img alt='tgicon' src={tgIcon}/>
                            </div>
                            <p>{t("reviewsStrong")}</p>
                        </div>
                    </a>
                    <a name='email'>
                        <div className='anyQwestions__wrap--point'>
                            <div>
                                <img alt='tgicon' src={mailIcon}/>
                            </div>
                            <p>yourguardvpn@gmail.com</p>
                        </div>
                    </a>
                    <a  name='FAQ' href='https://merciful-emery-841.notion.site/FAQ-112413d874bc80c19a02e29b0ed0c24e?pvs=4'>
                        <div className='anyQwestions__wrap--point'>
                            <div>
                                <img alt='FAQicon' src={fuckYouIcon}/>
                            </div>
                            <p>{t("faq")}</p>
                        </div>
                    </a>
                    
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default Landing